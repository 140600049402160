export const PATH = {
  SIGN_IN: "/sign-in",
  ROOT: "/",
  MAIN: "/main",
  LIVE_DETAIL: "/live-detail",
  NOT_FOUND: "/404",
  ACCESS_CHROME: "/access-chrome",
  DOCS__TERMSOFUSE: "/docs/termsofuse",
};

export default PATH;
