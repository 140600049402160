import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

export const GlobalStyles = createGlobalStyle`
    ${reset};

    *, *::after, *::before {
        padding: 0;
        margin: 0;
        box-sizing: border-box;

    }
    *::placeholder {
    }
    body {
      overscroll-behavior: auto;
      user-select: none;
      font-family: Spoqa Han Sans Neo;
      /* background-color: red; */
      background-color:  #FBFBFC !important;   
    }

    a {
      text-decoration: none;
    }
    ul {
      list-style: none;
    }
`;
