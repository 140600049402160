import React, { FunctionComponent } from "react";
import "antd/dist/antd.less";
import "./App.less";
import CommonRouter from "./CommonRouter";
import { BrowserRouter as Router } from "react-router-dom";

import { GlobalStyles } from "./styles/globalstyle";
import { RecoilRoot } from "recoil";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 60 * 60 * 1000 * 24,
    },
  },
});

const App: FunctionComponent<any> = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <GlobalStyles />
        <Router>
          <CommonRouter />
        </Router>
      </RecoilRoot>
    </QueryClientProvider>
  );
};

export default App;
