import React from "react";
import styled from "styled-components";

/** img */
import Spin from "images/spin_livelink.svg";

interface ISpinSpinnerProps {
  height?: string;
  width?: string;
  className?: string;
}
const SpinSpinner = ({
  width = "100vw",
  height = "100vh",
  className,
}: ISpinSpinnerProps) => {
  // const { categoryName } = history.query;
  return (
    <SpinSpinnerWrapper
      className={className}
      style={{
        height: height,
        width: width,
      }}
    >
      <img alt="스피너" src={Spin} />
    </SpinSpinnerWrapper>
  );
};

const SpinSpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default SpinSpinner;
