import React, { Suspense, lazy, FunctionComponent } from "react";
import { Route, Routes } from "react-router-dom";
import { routerMeta } from "meta";
import { assignRouteArrayProps } from "utils";
import SpinSpinner from "components/SpinSpinner";

interface ICustomRotuerProps {}

const lazyImport = (containerName: string) =>
  lazy(() => import(`containers/${containerName}`));

interface AssignRoute {
  Comp: any;
  propsArr: any | any[];
}

const publicAssignRouter: AssignRoute[] = Object.keys(
  routerMeta.publicRouterMeta
).map((componentKey: string) => {
  const propsArr: any = assignRouteArrayProps(
    routerMeta.publicRouterMeta[componentKey]
  );
  return {
    Comp: lazyImport(componentKey),
    propsArr,
  };
});

const privateAssignRouter: AssignRoute[] = Object.keys(
  routerMeta.privateRouterMeta
).map((componentKey: string) => {
  const propsArr: any = assignRouteArrayProps(
    routerMeta.privateRouterMeta[componentKey]
  );
  return {
    Comp: lazyImport(componentKey),
    propsArr,
  };
});

const CommonRouter: FunctionComponent<ICustomRotuerProps> = () => {
  return (
    <Suspense fallback={<SpinSpinner />}>
      <Routes>
        {publicAssignRouter.map(({ Comp, propsArr }) => {
          if (Array.isArray(propsArr)) {
            return propsArr.map((props) => {
              return <Route key={props.path} element={<Comp />} {...props} />;
            });
          } else {
            return (
              <Route key={propsArr.path} element={<Comp />} {...propsArr} />
            );
          }
        })}
        {privateAssignRouter.map(({ Comp, propsArr }) => {
          if (Array.isArray(propsArr)) {
            return propsArr.map((props) => {
              return <Route key={props.path} element={<Comp />} {...props} />;
            });
          } else {
            return (
              <Route key={propsArr.path} element={<Comp />} {...propsArr} />
            );
          }
        })}
      </Routes>
    </Suspense>
  );
};

export default CommonRouter;
