import { RouteProps } from "react-router";
import PATH from "constants/paths";

export type RouterMetaType = {
  [key: string]: string | Omit<RouteProps, "component">;
};

const publicRouterMeta: RouterMetaType = {
  Main: { path: PATH.MAIN },
  LiveDetail: { path: PATH.LIVE_DETAIL },
  Home: { path: PATH.ROOT },
  Termsofuse: { path: PATH.DOCS__TERMSOFUSE },
  Notfound: { path: "*" },
};

const privateRouterMeta: RouterMetaType = {
  // Main: { path: PATH.MAIN },
  // Category: { path: PATH.CATEGORY },
  // Home: { path: PATH.ROOT },
  // Termsofuse: { path: PATH.DOCS__TERMSOFUSE },
  // Notfound: { path: "*" },
};

export default { publicRouterMeta, privateRouterMeta };
